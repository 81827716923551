import React from 'react';

import Layout from '../components/Layout';


const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>TESTIMONIALS</h2>
          <p>See what our customers have to say</p>
        </div>
      </header>

      <section id="one" className="wrapper spotlight style1">
        <div className="inner">       
          <div className="content">   
          <h3>   
            <blockquote>
          "I went to see inside the store where they make the product and I can assure you that it is a very clean place.
          They use high quality meat and have an extraordinary traditional recipe. I recommend to all my friends ....and all those who have tried the quality of the product have remained their loyal customers.
          Transylvania Meat Co Inc....is the best!!!" 
          </blockquote>
          </h3>    
          
          <h3>- Buzila Canalos Manuela Corina</h3>
          </div>
        </div>
      </section>
     

      <div className="wrapper alt">
        <div className="inner">
            <h3 className="">
          <blockquote>
          "Mititei from Chicago. It is very nice that I found a traditional recipe from Transylvania here. I could feel the taste of home for a second."
          </blockquote>
         
          <blockquote>
          "Simply the tastiest meat I've tried in Chicago. These mititei are perfect for a sunday barbeque."
          </blockquote>

          <blockquote>
          "cei mai buni mititei de pe piata carne de calitate si gust extraordinar!!"
          </blockquote>
          
          <blockquote>
          "Transylvania's mititei are incredable. They are fresh, tasty and very easy to cook."
          </blockquote>
          </h3>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
